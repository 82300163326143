import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { IS_PROD,IS_DEMO, SENTRY_DNS, PROJECT_ENV } from '@/constants';
import router from '../router';

export const setupSentry = () => {
  console.log("sentry config start.")
  if (!IS_PROD) return;

  Sentry.init({
    Vue,
    dsn: SENTRY_DNS,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['local.*.nawa-dd.com', '*.nawa-dd.com', /^\//],
      }),
      new Sentry.Replay(),
    ],
    // This sets the sample rate at 10%. You may want to change it to 100% while in development
    // and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: `nawa-spa-${PROJECT_ENV}`,
    tracesSampleRate: 1.0,
    logErrors: true,
  });
  console.log("sentry config done.")
};
export default setupSentry;
