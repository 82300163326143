<template>
  <div class="footer">
    <a
      href="https://care.ncnp.gov.sa/csm?id=service_details&sys_id=29c6abf345109a50b90e0dcc5008f08f"
      target="_blank"
      rel="noopener noreferrer"
      class="support-button"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        xml:space="preserve"
        aria-hidden="true"
      ><g /><g /><g /><g><g><g>
        <path
          d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,
        1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.
        6,3.4,3C13.4,10.4,11,10.8,11,12.3z"
        /><circle
          cx="10"
          cy="15"
          r="1"
        /></g><path
        d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,
      10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"
      /></g></g></svg>
      الدعم
    </a>
  </div>
</template>

<script>
export default {
  name: 'Support',
};
</script>

<style src="./styles.scss" lang="scss" scoped />
